.dropdown {
  width: 100%;

  .dropdown-trigger {
    width: 100%;

    .button {
      width: 100%;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .dropdown-menu {
    min-width: 100%;

    .dropdown-content {
      max-height: 320px;
      overflow: auto;
      width: 100%;
    }
  }

  &.is-language {
    .dropdown-menu {
      padding-top: 0;
    }
  }

  &.is-searchable {
    .dropdown-content {
      min-width: 180px;

      .dropdown-search-item {
        position: sticky;
        top: 0;
        padding: 12px;
        background: $white;
        z-index: 1;
      }
    }
  }

  &.is-narrow {
    .tag {
      flex: none;
      font-size: map-get($typo, 'text-xs', 'font-size');
      padding: 4px 8px;
    }

    .button {
      span {
        line-height: 1.5;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .dropdown-content {
      border: 1px solid $border;
      background: $white;

      .dropdown-item {
        padding-right: 1rem;

        &:hover.is-active {
          background: $secondary;
        }
      }
    }

    &.is-multiline {
      .dropdown-item {
        white-space: wrap;

        &:not(:last-child) {
          border-bottom: 1px solid $secondary;
        }
      }
    }
  }

  &.is-header-nav {
    max-width: 400px;

    .dropdown-trigger {
      .button {
        font-weight: $weight-bold;
        border-width: 2px;
        height: 62px;
        justify-content: space-between;
      }
    }

    .button,
    .dropdown-item {
      font-size: map-get($typo, 'title-small', 'font-size');
    }

    .dropdown-menu {
      .dropdown-content {
        border: 2px solid $primary;
        font-weight: $weight-semibold;
      }
    }
  }
}
