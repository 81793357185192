// @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://rsms.me/inter/inter.css');
@import 'bulma/sass/utilities/mixins.sass';

* {
  font-family: Inter, sans-serif;
  font-optical-sizing: auto;
  font-feature-settings: 'liga' 1, 'calt' 1, 'ss07' 1, 'ss08' 1;
}

html[lang='de'] {
  .is-title-hero {
    hyphens: auto;
    overflow-wrap: anywhere;
  }
}

@supports (font-variation-settings: normal) {
  * {
    font-family: InterVariable, sans-serif;
  }
}

$typo: (
  'title-hero': (
    'font-size': clamp(2.75rem, 2.2312rem + 2.2134vw, 4.5rem),
    'line-height': 1.05,
    'letter-spacing': clamp(-0.0937rem, -0.1076rem + 0.0593vw, -0.0469rem),
    'font-weight': $weight-bold,
  ),
  'title-large': (
    'font-size': clamp(2.5rem, 2.1294rem + 1.581vw, 3.75rem),
    'line-height': 1.1,
    'letter-spacing': clamp(-0.0625rem, -0.0671rem + 0.0198vw, -0.0469rem),
    'font-weight': $weight-bold,
  ),
  'title-medium': (
    'font-size': clamp(2rem, 1.7036rem + 1.2648vw, 3rem),
    'line-height': 1.15,
    'letter-spacing': clamp(-0.0469rem, -0.0561rem + 0.0395vw, -0.0156rem),
    'font-weight': $weight-bold,
  ),
  'title-normal': (
    'font-size': clamp(1.75rem, 1.6018rem + 0.6324vw, 2.25rem),
    'line-height': 1.15,
    'letter-spacing': normal,
    'font-weight': $weight-bold,
  ),
  'title-small': (
    'font-size': clamp(1.5rem, 1.4259rem + 0.3162vw, 1.75rem),
    'line-height': 1.2,
    'letter-spacing': normal,
    'font-weight': $weight-bold,
  ),
  'heading-normal': (
    'font-size': clamp(1.25rem, 1.1759rem + 0.3162vw, 1.5rem),
    'line-height': 1.2,
    'font-weight': $weight-bold,
  ),
  'text-large': (
    'font-size': clamp(1.125rem, 1.0879rem + 0.1581vw, 1.25rem),
    'line-height': 1.25,
  ),
  'text-detail': (
    'font-size': clamp(1rem, 0.9629rem + 0.1581vw, 1.125rem),
    'line-height': 1.3,
    'font-weight': $weight-semibold,
  ),
  'text-normal': (
    'font-size': 1.125rem,
    // 'line-height': 1.4,
  ),
  'text-small': (
    'font-size': 1rem,
    // 'line-height': 1.4,
  ),
  'text-xs': (
    'font-size': 0.875rem,
    // 'line-height': 1.4,
  ),
  'text-tiny': (
    'font-size': 0.75rem,
    // 'line-height': 1.4,
  ),
);

@each $name, $type in $typo {
  .is-#{$name} {
    @each $key, $value in $type {
      #{$key}: $value;
    }
  }

  @include tablet {
    .is-#{$name}-tablet {
      @each $key, $value in $type {
        #{$key}: $value !important;
      }
    }
  }

  @include desktop {
    .is-#{$name}-desktop {
      @each $key, $value in $type {
        #{$key}: $value !important;
      }
    }
  }
}

.is-scroll-heading {
  scroll-margin-top: calc(80px + $fluid-space-v5);
}

a:not(.box.is-actor) {
  &:hover {
    .is-text-large {
      text-decoration: underline;
    }
  }
}
