@import './variables.scss';

@import './buefy.scss';
@import 'glightbox/dist/css/glightbox.css';

@import './layout.scss';
@import './forms.scss';
@import './mixins.scss';

@import './bulma/columns.scss';

@import './elements/button.scss';
@import './elements/box.scss';
@import './elements/content.scss';
@import './elements/icon.scss';
@import './elements/image.scss';
@import './elements/tag.scss';

@import './components/accordion.scss';
@import './components/carousel.scss';
@import './components/dropdown.scss';
@import './components/glightbox.scss';
@import './components/modal.scss';
@import './components/navbar.scss';
