.button {
  font-weight: $weight-semibold;
  padding: $fixed-space-350 $fixed-space-300;
  line-height: 1;

  .icon {
    width: 1em;
    height: 1em;

    &:first-child {
      &:last-child {
        margin: 0;
      }

      &:not(:last-child) {
        margin-left: 0;
        margin-right: $fixed-space-200;
      }
    }

    &:last-child {
      &:not(:first-child) {
        margin-right: 0;
        margin-left: $fixed-space-200;
      }
    }
  }

  &:hover {
    text-decoration: underline;
  }

  &.is-square {
    aspect-ratio: 1;

    &.p-0 {
      height: auto;
    }
  }

  &.is-transparent {
    background: transparent;
    border-color: transparent;
  }

  &.is-black-50 {
    &.is-outlined:hover {
      color: $black;
      border-color: $black;
      background: transparent;
    }
  }

  &.is-white-50 {
    &.is-outlined:hover {
      color: $white;
      border-color: $white;
      background: transparent;
    }
  }

  &.is-text {
    padding: 0;
    height: auto;
    vertical-align: middle;
  }

  &.is-header-menu-item {
    padding: $fluid-space-v3 $fixed-space-200;
    height: auto;
    border-bottom: 4px solid transparent;
    font-size: map-get($typo, 'title-normal', 'font-size');
    font-weight: $weight-bold;

    &:hover,
    &.is-active {
      text-decoration: none;
      border-bottom-color: $primary;
    }

    &.is-active {
      background: transparent;
    }

    &:hover {
      background: $secondary;
    }
  }

  &.is-header-submenu-item {
    padding: $fixed-space-100 $fixed-space-200;
    height: 33px;

    &:hover {
      background: $secondary;
      text-decoration: none;
    }
  }

  &.is-secondary-light {
    background: $secondary-light;
    border: 1px solid $secondary;
    padding: $fluid-space-v3;
    height: auto;
    min-height: 70px;

    &.is-active,
    &:hover {
      background: $white;
      border-color: $primary;
      box-shadow: none;
    }
  }

  &.is-document {
    text-align: left;
    white-space: normal;
    word-break: break-word;
    border-width: 2px;

    & + & {
      margin-top: 1rem;
    }

    .icon:last-child {
      color: $black-50;
    }

    &:hover {
      .icon:last-child {
        color: $primary;
      }
    }
  }

  &.is-follow {
    &:not(.is-outlined) {
      .text span:last-child,
      .icon > :last-child {
        display: none;
      }
    }

    &.is-outlined {
      .text span:first-child,
      .icon > :first-child {
        display: none;
      }
    }
  }
}

.buttons {
  gap: $fixed-space-300;

  &:last-child {
    margin-bottom: 0;
  }

  .button {
    margin-bottom: 0;

    &:not(:last-child):not(.is-fullwidth) {
      margin-right: 0;
    }
  }

  &.is-centered:not(.has-addons) .button:not(.is-fullwidth) {
    margin-left: 0;
    margin-right: 0;
  }
}
