html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;

  > main {
    flex: 1;
  }
}

.container {
  // width: 91%;
  max-width: 1710px;
  min-width: 300px;
  margin-left: auto;
  margin-right: auto;

  &.is-wide {
    max-width: 1920px;
  }
}

header.header {
}

.columns {
  &.is-list {
    .column .is-role {
      padding-top: $fixed-space-100;
      padding-bottom: $fixed-space-100;
    }
  }

  &.has-separators {
    .column:not(:last-child) > .box {
      border-bottom: 1px solid $primary;
      padding-bottom: $fluid-space-v5;
    }

    &.include-last {
      .column > .box {
        border-bottom: 1px solid $primary;
        padding-bottom: $fluid-space-v5;
      }
    }
  }
}

footer.footer {
  .buttons {
    .button {
      &:hover {
        opacity: 0.7;
      }

      .icon {
        font-size: $fluid-space-v5;
      }
    }
  }

  sup {
    font-size: 0.875rem;
    display: block;
    // vertical-align: baseline;
    margin-bottom: $fixed-space-100;
  }
}

section {
  &.section {
    padding: $fluid-section-top $fluid-section-sides $fluid-section-bottom;

    footer.footer-section {
      text-align: center;
      margin-top: $fluid-space-v7;

      .button {
        // margin: 0 auto;
      }
    }
  }

  &.is-full-mobile {
    @include mobile {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.is-program-head {
    border: 1px solid $border;
    position: sticky;
    top: 0;
    z-index: 9;
    background: $white;
    border-width: 1px 0;
  }

  &.is-notifications {
    pointer-events: none;
    left: 16px;
    right: 16px;
    top: 16px;
    padding: 0;
    position: fixed;
    z-index: 39;

    @include tablet {
      top: 40px;
    }

    .notification {
      margin: 0 auto;
      pointer-events: all;
      max-width: 480px;
      position: relative;
      padding: $fixed-space-300 48px $fixed-space-300 $fixed-space-400;

      > .button {
        position: absolute;
        right: 0;
        top: 0;
        width: 46px;
        height: 46px;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      .buttons {
        margin-top: 1rem;

        .button {
          text-decoration: none;
          font-size: 1rem;
          height: 36px;
        }
      }
    }
  }
}

.is-limited {
  &-720 {
    max-width: 720px;
  }

  &-840 {
    max-width: 840px;
  }

  &-960 {
    max-width: 960px;
  }

  &-1080 {
    max-width: 1080px;
  }
}

// .is-ticket-grid {
//   display: grid;
//   gap: $fluid-space-v4;
//   @include tablet {
//     grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
//   }
// }
