.image {
  img {
    display: block;
    width: 100%;

    &.is-cover {
      object-fit: cover;
    }

    &.is-contain {
      object-fit: contain;
    }
  }

  .has-ratio,
  iframe {
    width: 100%;
    height: 100%;
  }

  &.is-4by3 {
    img,
    .has-ratio {
      aspect-ratio: 4/3;

      @include desktop {
        aspect-ratio: 16/9;
      }
    }
  }

  &.is-16by9 {
    img,
    iframe {
      aspect-ratio: 16/9;
    }
  }

  &.is-1by1 {
    .has-ratio,
    img {
      aspect-ratio: 1;
    }
  }

  &.is-3by2 img {
    aspect-ratio: 3/2;
  }

  &.is-3by1 {
    img,
    iframe {
      aspect-ratio: 3/1;
    }
  }

  .is-2by1 {
    img,
    iframe {
      aspect-ratio: 2/1;
    }
  }

  &.is-header img {
    aspect-ratio: 1;
    @include responsive-aspect-ratio;
  }
}

.has-ratio.is-placeholder {
  background: $secondary;
}
