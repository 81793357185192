// COLORS
$black: rgb(0, 0, 0);
$black-5: rgba(21, 21, 23, 0.05);
$black-10: rgba(21, 21, 23, 0.1);
$black-15: rgba(21, 21, 23, 0.15);
$black-35: rgba(21, 21, 23, 0.35);
$black-50: rgba(21, 21, 23, 0.5);
$black-100: rgb(21, 21, 23);

$white: rgb(255, 255, 255);
$white-5: rgba(255, 255, 255, 0.05);
$white-10: rgba(255, 255, 255, 0.1);
$white-15: rgba(255, 255, 255, 0.15);
$white-20: rgba(255, 255, 255, 0.2);
$white-30: rgba(255, 255, 255, 0.3);
$white-50: rgba(255, 255, 255, 0.5);
$white-70: rgba(255, 255, 255, 0.7);
$white-85: rgba(255, 255, 255, 0.85);
$white-90: rgba(255, 255, 255, 0.9);
$white-100: rgb(255, 255, 255);

// --------------------------------------------- SPACING

// SECTION
$fluid-section-sides: clamp(1rem, 0.1942rem + 4.0288vw, 4.5rem);
$fluid-section-top: clamp(2rem, 1.3094rem + 3.4532vw, 5rem);
$fluid-section-bottom: clamp(3rem, 2.5396rem + 2.3022vw, 5rem);
$fluid-logo: clamp(4rem, 3.6259rem + 1.8705vw, 5.625rem);

// SPACE
$fluid-space-v1: clamp(0.25rem, 0.1924rem + 0.2878vw, 0.5rem);
$fluid-space-v2: clamp(0.5rem, 0.3849rem + 0.5755vw, 1rem);
$fluid-space-v3: clamp(1rem, 0.8849rem + 0.5755vw, 1.5rem);
$fluid-space-v4: clamp(1.5rem, 1.3849rem + 0.5755vw, 2rem);
$fluid-space-v5: clamp(2.25rem, 2.0773rem + 0.8633vw, 3rem);
$fluid-space-v6: clamp(3rem, 2.7698rem + 1.1511vw, 4rem);
$fluid-space-v7: clamp(4rem, 3.7698rem + 1.1511vw, 5rem);
$fluid-space-v8: clamp(5rem, 4.4245rem + 2.8777vw, 7.5rem);
$fluid-space-v9: clamp(10rem, 8.8489rem + 5.7554vw, 15rem);

$fixed-space-100: 0.25rem;
$fixed-space-150: 0.375rem;
$fixed-space-200: 0.5rem;
$fixed-space-250: 0.625rem;
$fixed-space-300: 0.75rem;
$fixed-space-350: 0.875rem;
$fixed-space-400: 1rem;
$fixed-space-500: 1.25rem;
$fixed-space-600: 1.5rem;
$fixed-space-700: 1.75rem;
$fixed-space-800: 2rem;

// ICON
$fluid-icon-v0: clamp(2rem, 1.8273rem + 0.8633vw, 2.75rem);
$fluid-icon-v1: clamp(1.75rem, 1.6349rem + 0.5755vw, 2.25rem);
$fluid-icon-v2: clamp(1rem, 0.9712rem + 0.1439vw, 1.125rem);
$fixed-icon-1: 1.25rem;
$fixed-icon-2: 1.5rem;
$fixed-icon-3: 2rem;
