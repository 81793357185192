.tag {
  &:not(body) {
    font-weight: $weight-semibold;
    min-height: 1.875em;
    height: auto;
    font-size: 1rem;
    line-height: 1.375;
    padding: $fixed-space-100 $fixed-space-300;
    white-space: normal;

    .icon {
      &:first-child {
        &:last-child {
          margin: 0;
        }

        &:not(:last-child) {
          margin-left: 0;
          margin-right: $fixed-space-150;
        }
      }
    }
  }
}

.tags {
  gap: $fixed-space-200;

  &:last-child,
  &:not(:last-child) {
    margin-bottom: 0;
  }

  .tag {
    &,
    &:not(:last-child) {
      margin: 0;
    }
  }
}
