.swiper {
  --swiper-pagination-color: #{$white};
  --swiper-pagination-bullet-inactive-color: #{$white-50};
  --swiper-pagination-bottom: #{$fluid-space-v4};

  .swiper-nav {
    &.swiper-button-disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .swiper-wrapper {
    .swiper-slide {
      box-sizing: border-box;
    }
  }

  .swiper-footer:has(.swiper-button-lock) {
    display: none;
  }

  &.is-featured {
    aspect-ratio: 9/16;
    @include responsive-aspect-ratio;

    .swiper-slide {
      position: relative;

      .slide-content {
        position: absolute;
        padding: $fluid-space-v4 $fluid-section-sides;
        z-index: 2;
        bottom: 0;

        .is-title-hero {
          margin: $fluid-space-v2 0;
        }

        .button {
          margin: $fluid-space-v4 0 $fluid-space-v6;
        }
      }

      &:after {
        content: '';
        @extend .is-overlay;
        z-index: 1;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 0.7) 100%
        );
      }

      img,
      .has-ratio {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .swiper-nav {
      display: none;
      cursor: pointer;

      @include desktop {
        display: block;
        position: absolute;
        z-index: 1;
        color: $white;
        top: 50%;
        transform: translateY(-50%);
        font-size: 44px;

        .icon {
          display: block;
        }

        &.is-prev {
          left: 8px;
        }

        &.is-next {
          right: 8px;
        }
      }
    }
  }

  &.is-list {
    overflow: visible;
    --swiper-pagination-bottom: calc(-1 * #{$fluid-space-v4});
    margin-left: calc(-1 * $fluid-space-v4);

    .swiper-wrapper {
      margin-top: $fluid-space-v5;

      .swiper-slide {
        padding-left: $fluid-space-v4;
      }
    }

    .swiper-footer {
      margin-top: $fluid-space-v5;
      padding-left: $fluid-space-v4;

      .buttons {
        display: none;
        justify-content: flex-end;
        margin-right: $fluid-space-v9;

        @include desktop {
          display: flex;
        }
      }
    }

    &.is-actors {
      margin-left: calc(-1 * $fluid-space-v3);
      .swiper-wrapper {
        .swiper-slide {
          padding-left: $fluid-space-v3;
        }
      }
    }

    &.is-gallery {
      .swiper-slide {
        width: auto;
      }

      .image {
        cursor: zoom-in;

        img {
          width: auto;
          height: clamp(11.25rem, 4.6313rem + 33.0935vw, 40rem);
        }
      }
    }

    &.is-review {
      .swiper-slide {
        @include desktop {
          opacity: 0.4;
          transition: opacity 0.5s, filter 0.5s;
          width: 840px;

          &-active {
            opacity: 1;
          }
        }
      }

      .swiper-footer {
        .buttons {
          display: flex;
          margin-right: 0;
          justify-content: center;
        }
      }
    }
  }

  &.is-calendar {
    display: flex;

    @include tablet {
      border: 1px solid $border;
      border-width: 0 1px;
    }

    .swiper-wrapper {
      width: auto;

      .swiper-slide {
        width: auto;

        .button {
          white-space: normal;
          padding: $fluid-space-v3;
          height: 62px;

          &:hover {
            text-decoration: none;
          }
        }

        &:first-child {
          padding-left: 4px;
        }

        &:last-child {
          padding-right: 70px;
        }

        @include tablet {
          .button {
            height: 82px;
          }

          &:first-child {
            padding-left: 44px;
          }

          &:last-child {
            padding-right: 114px;
          }
        }
      }
    }

    > .button {
      z-index: 2;
      position: absolute;
      height: 100%;
      padding: $fixed-space-300;

      &.swiper-nav {
        display: none;

        @include tablet {
          display: inline-flex;

          &.swiper-button-lock {
            display: none;
          }
        }
      }

      &.is-prev {
        left: 0;
      }

      &.is-next {
        right: 0;
      }

      &.is-calendar-toggle {
        right: 44px;
        border: 1px solid $border;
        border-width: 0 1px;
        padding: $fixed-space-400;
      }
    }
  }
}

.carousel-container {
  overflow: hidden;
}
