@charset "utf-8";
@import './primitives.scss';

//////////////////////////////////////// COLOURS
$primary: $black-100;
$accent: #fff200;

$secondary: #e8edee;
$secondary-light: #e8edee66;

$articles: #fff200;

// derived
$background: $white;
$text: $primary;
$text-strong: $primary;

$link: $primary;
$link-invert: $white;
$link-hover: $primary;
$link-focus: $primary;
$link-active: $primary;

$danger: #c00f0c;
$danger-invert: #fee9e7;
$danger-light: #fcb3ad;

$warning: #921661;
$warning-light: #ffebf7;
$warning-dark: #760e4d;
$warning-invert: #ffccea;

$success: #14ae5c;
$success-invert: #85e0a3;
$success-dark: #009951;
$success-light: #ebffee;

$info: $secondary;
$info-light: $secondary-light;
$info-dark: $primary;

$custom-colors: (
  'white': (
    $white,
    $primary,
  ),
  'primary': (
    $primary,
    $white,
  ),
  'accent': (
    $accent,
    $primary,
  ),
  'secondary': (
    $secondary,
    $primary,
  ),
  'black-50': (
    $black-50,
    $white,
  ),
  'white-50': (
    $white-50,
    $primary,
  ),
  'articles': (
    $articles,
    $primary,
  ),
);

$border: $primary;
$border-hover: $primary;

$gap: 30px;
$block-spacing: 20px;

$radius: 0;
$radius-small: 6px;

$control-height: 50px;
$control-radius: $radius;
$control-padding-horizontal: 1em;
$control-line-height: inherit;

$tablet: 768px;
$desktop: 1024px;
$widescreen: 1366px;
$fullhd: 1620px;

@import 'bulma/sass/utilities/initial-variables.sass';
@import './typo.scss';

$size-small: map-get($typo, 'text-small', 'font-size');
$size-normal: map-get($typo, 'text-normal', 'font-size');
$size-medium: map-get($typo, 'text-large', 'font-size');
$size-large: map-get($typo, 'heading-normal', 'font-size');
@import 'bulma/sass/utilities/_all.sass';

//
$family-primary: 'Inter', sans-serif;
$body-color: $black;
$body-size: 16px;
$body-line-height: 1.4;
$body-weight: $weight-medium;
$hr-height: 1px;
$hr-margin: 0;
$hr-background-color: $border;
@import 'bulma/sass/base/_all.sass';

//
$navbar-height: 3.75rem;
$navbar-item-hover-background-color: transparent;
$navbar-background-color: transparent;
$navbar-burger-color: $white;
$navbar-breakpoint: $tablet;
@import 'bulma/sass/components/navbar.sass';

//
$card-shadow: none;
$card-content-padding: 1rem 1.25rem 1.25rem;
@import 'bulma/sass/components/card.sass';

//
$dropdown-content-background-color: $secondary;
$dropdown-item-hover-background-color: $accent;
$dropdown-content-offset: $fixed-space-200;
$dropdown-menu-min-width: 150px;
$dropdown-content-shadow: none;
$dropdown-content-padding-top: 0;
$dropdown-content-padding-bottom: 0;
$dropdown-item-active-background-color: $accent;
$dropdown-item-active-color: inherit;
@import 'bulma/sass/components/dropdown.sass';

//
$modal-content-width: 640px;
$modal-content-margin-mobile: 0;
$modal-content-spacing-mobile: 0px;
$modal-content-spacing-tablet: 0px;
@import 'bulma/sass/components/modal.sass';

//
$pagination-current-background-color: $accent;
$pagination-current-color: $primary;
$pagination-current-border-color: transparent;
$pagination-border-color: transparent;
$pagination-hover-border-color: $primary;
$pagination-disabled-border-color: transparent;
$pagination-focus-border-color: $primary;
$pagination-shadow-inset: none;
$pagination-disabled-background-color: $white;
@import 'bulma/sass/components/pagination.sass';

//
@import 'bulma/sass/components/tabs.sass';

//
$section-padding: 0;
$section-padding-desktop: 0;
$footer-padding: 0;
$footer-background-color: $accent;
@import 'bulma/sass/layout/_all.sass';

//
$button-text-decoration: none;
$button-text-hover-color: inherit;
$button-text-hover-background-color: transparent;
// $button-disabled-border-color: $black-50;
@import 'bulma/sass/elements/button.sass';
@import 'bulma/sass/elements/content.sass';
@import 'bulma/sass/elements/icon.sass';
@import 'bulma/sass/elements/notification.sass';

//
$table-cell-padding: $fixed-space-300 $fixed-space-400;
$table-cell-border: 1px solid $black-10;
$table-striped-row-even-background-color: $secondary-light;
$table-row-hover-background-color: $accent;
$table-striped-row-even-hover-background-color: $accent;
@import 'bulma/sass/elements/table.sass';

//
// $tag-background-color: $secondary;
@import 'bulma/sass/elements/tag.sass';

//
$input-focus-box-shadow-color: $accent;
$label-weight: $weight-medium;
$input-disabled-border-color: $black-50;
@import 'bulma/sass/form/_all.sass';

// columns are custom
$column-gap: 0px;
$variable-columns: false;
@import 'bulma/sass/grid/columns.sass';

//
$spacing-values: (
  'v1': $fluid-space-v1,
  'v2': $fluid-space-v2,
  'v3': $fluid-space-v3,
  'v4': $fluid-space-v4,
  'v5': $fluid-space-v5,
  'v6': $fluid-space-v6,
  'v7': $fluid-space-v7,
  'v8': $fluid-space-v8,
  'v9': $fluid-space-v9,
  '0': 0,
  '100': $fixed-space-100,
  '150': $fixed-space-150,
  '200': $fixed-space-200,
  '250': $fixed-space-250,
  '300': $fixed-space-300,
  '350': $fixed-space-350,
  '400': $fixed-space-400,
  '500': $fixed-space-500,
  '600': $fixed-space-600,
  '700': $fixed-space-700,
  '800': $fixed-space-800,
  'auto': auto,
);

@import 'bulma/sass/helpers/_all.sass';
