.content {
  a {
    text-decoration: underline;
  }
}

.section.is-article {
  .article-block {
    padding-top: $fluid-space-v5;
    padding-bottom: $fluid-space-v5;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &.block-heading_block {
      padding-bottom: 0;
    }

    &.block-block_quote {
      blockquote {
        padding-bottom: $fluid-space-v3;
        border-bottom: 1px solid $secondary;

        p::before {
          content: '„';
        }

        p::after {
          content: '”';
        }
      }
    }

    &.block-carousel {
      padding-top: 0;
    }

    &.block-pages_links {
      .box:not(:last-child) {
        margin-bottom: 1.25rem;
      }
    }
  }
}
