.form-container {
  max-width: 520px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: map-get($typo, 'text-normal', 'font-size');

  .field {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    .label {
      font-size: map-get($typo, 'text-xs', 'font-size');
      margin-bottom: $fixed-space-150;
    }

    .help-text {
      margin-bottom: $fixed-space-250;

      ul:first-child {
        margin-top: 0;
      }
    }

    .input {
      border-width: 2px;
      padding: $fixed-space-200 $fixed-space-300;

      &::placeholder {
        color: $black-50;
      }
    }

    .dropdown > .dropdown-trigger > .button,
    .dropdown .dropdown-content {
      border-width: 2px;
    }
  }

  h2 {
    margin-bottom: $fixed-space-600;
  }

  .button[type='submit'] {
    margin-top: $fixed-space-600;
    margin-left: auto;
    display: flex;
    width: 100%;

    @include tablet {
      width: auto;
      min-width: 260px;
      width: auto;
    }
  }
}
