.box {
  header {
    position: relative;

    .tag {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  &.is-event {
    &.is-horizontal {
      .box-content {
        margin-top: $fluid-space-v4;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .buttons {
          order: 2;
          margin-top: $fluid-space-v3;
          width: 100%;

          .button {
            width: 100%;
          }
        }

        .box-info {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: $fixed-space-200;

          @include touch {
            margin-top: $fluid-space-v4;
            order: 1;

            .is-last-mobile {
              order: 1;
            }
          }

          @include desktop {
            gap: $fixed-space-300 $fluid-space-v5;
          }
        }
      }

      @include tablet {
        @include horizontal-box;

        .box-content {
          margin-top: 0;

          .buttons {
            margin: auto 0 0 auto;
            width: auto;

            .button {
              width: auto;
            }
          }

          .box-info {
            gap: $fixed-space-400;
            margin-bottom: $fluid-space-v3;
          }
        }
      }
    }

    header:hover .image:after {
      @include overlay;
      content: '';
      display: block;
      padding: auto;
      border: 6px solid $accent;
    }
  }

  &.is-season {
    .is-title-small {
      margin: $fixed-space-300 0 $fluid-space-v1;
    }

    &.is-past {
      border-top: 1px solid $border;

      .is-title-small {
        margin-top: $fixed-space-400;
      }
    }

    header:hover .image:after {
      @include overlay;
      content: '';
      display: block;
      padding: auto;
      border: 6px solid $accent;
    }
  }

  &.is-article {
    header {
      .tag {
        padding: $fixed-space-300;
      }
    }

    .tags {
      margin-top: $fixed-space-300;

      > .icon {
        display: none;
      }
    }

    .is-title-small {
      margin: $fluid-space-v1 0;
    }

    &.is-horizontal {
      @include tablet {
        @include horizontal-box;

        header .tag {
          display: none;
        }

        .box-content {
          .tags {
            margin-top: 0;

            > .icon {
              display: flex;
              font-size: $fixed-icon-2;
            }
          }

          .is-title-small {
            margin: $fixed-space-300 0;
          }
        }
      }
    }

    &:hover {
      .image:after {
        @include overlay;
        content: '';
        display: block;
        padding: auto;
        border: 6px solid $white;
      }
    }
  }

  &.is-actor {
    .box-content {
      padding-top: $fixed-space-400;
      color: $white;

      .is-text-small {
        color: $secondary;
      }
    }

    &.is-horizontal {
      display: flex;
      background: $primary;

      header {
        .image {
          img {
            width: $fluid-space-v8;
          }
        }
      }

      .box-content {
        flex: 1;
        padding: $fixed-space-400;
      }
    }

    &:hover {
      .box-content,
      .is-text-small {
        color: $accent;
      }

      .image:after {
        @include overlay;
        content: '';
        display: block;
        padding: auto;
        border: 3px solid $accent;
      }
    }
  }

  &.is-ticket {
    position: relative;
    border-left: 4px dashed $white;
    border-right: 4px dashed $white;

    .box-container {
      background: $white;
      padding: $fixed-space-400;
    }

    .box-content {
      .is-text-large {
        margin: $fixed-space-150 0;
      }

      margin-bottom: $fixed-space-300;
    }
  }

  &.is-review {
    background: $white;
    padding: $fluid-space-v3;
    position: relative;

    footer {
      .button {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }

  &.is-role {
    padding-left: $fixed-space-400;
    border-left: 1px solid $border;

    &.has-text-white {
      border-color: $secondary;
    }
  }

  &.is-link {
    display: flex;
    align-items: center;
    background: $secondary-light;
    border: 1px solid $secondary;

    &:hover {
      border: 1px solid $black-50;
    }

    .image {
      flex: none;
      width: 120px;
    }

    .box-content {
      flex: 1;
    }
  }

  &.is-search {
    padding: $fixed-space-200;

    &:hover {
      background: $accent;
    }
  }

  &.is-placeholder {
    padding: $fluid-space-v3;
    border: 1px dashed $border;
    text-align: center;
    font-weight: $weight-semibold;

    .button {
      margin-top: $fixed-space-500;
    }
  }
}

a.box {
  display: block;
}

.notification {
  &.is-success {
    border: 1px solid $success-dark;
  }

  &.is-warning {
    border: 1px solid $warning-dark;
  }

  &.is-info {
    border: 1px solid $info-dark;
  }
}
