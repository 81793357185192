// bulma columns rework with custom spacing values for columnGap

.columns {
  --columnGap: calc(#{$fluid-space-v4} / 2);
  margin: calc(-1 * var(--columnGap));

  &:last-child {
    margin-bottom: calc(-1 * var(--columnGap));
  }

  &:not(:last-child) {
    margin-bottom: calc(-1 * var(--columnGap));
  }

  > .column {
    padding: var(--columnGap);
  }

  @each $name, $value in $spacing-values {
    &.is-#{$name} {
      --columnGap: calc(#{$value} / 2);
    }
    @include mobile {
      &.is-#{$name}-mobile {
        --columnGap: calc(#{$value} / 2);
      }
    }
    @include tablet {
      &.is-#{$name}-tablet {
        --columnGap: calc(#{$value} / 2);
      }
    }
    @include tablet-only {
      &.is-#{$name}-tablet-only {
        --columnGap: calc(#{$value} / 2);
      }
    }
    @include touch {
      &.is-#{$name}-touch {
        --columnGap: calc(#{$value} / 2);
      }
    }
    @include desktop {
      &.is-#{$name}-desktop {
        --columnGap: calc(#{$value} / 2);
      }
    }
    @include desktop-only {
      &.is-#{$name}-desktop-only {
        --columnGap: calc(#{$value} / 2);
      }
    }
    @include widescreen {
      &.is-#{$name}-widescreen {
        --columnGap: calc(#{$value} / 2);
      }
    }
    @include widescreen-only {
      &.is-#{$name}-widescreen-only {
        --columnGap: calc(#{$value} / 2);
      }
    }
    @include fullhd {
      &.is-#{$name}-fullhd {
        --columnGap: calc(#{$value} / 2);
      }
    }
  }
}
