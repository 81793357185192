@import 'buefy/src/scss/utils/_all.scss';

$loading-background: rgba(255, 255, 255, 0.5);
$loading-background-legacy: $black;
// $dropdown-background-color: $black;

// $checkbox-background-color: $grey;
// $checkbox-border-color: $grey-light;
// $checkbox-border-radius: $size-3;
// $checkbox-border-width: 1px;
// $checkbox-size: $size-3;

@import 'buefy/src/scss/components/_checkbox.scss';
@import 'buefy/src/scss/components/_datepicker.scss';
@import 'buefy/src/scss/components/_dropdown.scss';
@import 'buefy/src/scss/components/_form.scss';
@import 'buefy/src/scss/components/_radio.scss';
@import 'buefy/src/scss/components/_table.scss';
@import 'buefy/src/scss/components/_tag.scss';
@import 'buefy/src/scss/components/_loading.scss';
@import 'buefy/src/scss/components/_modal.scss';

.b-table {
  .table {
    border-radius: 0;
    border-top: 1px solid $primary;

    td {
      vertical-align: middle;
    }

    th {
      padding-top: $fixed-space-400;
      padding-bottom: $fixed-space-400;
    }

    tr.is-empty td {
      padding: $fluid-space-v3;
      text-align: center;
    }
  }

  .table-wrapper:not(:last-child) {
    margin-bottom: $fluid-space-v5;
  }
}

.b-tabs {
  .is-toggle {
    margin-bottom: 48px;
  }
}

.pagination {
  &.is-centered {
    justify-content: center;

    .pagination-list {
      flex: none;
    }
  }
}

.datepicker > .dropdown > .dropdown-menu > .dropdown-content {
  max-height: none;
  padding: 16px 0;
}

.timepicker {
  .field.is-grouped.is-grouped-centered {
    align-items: center;
  }
}
