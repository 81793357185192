.block-accordion {
  .accordion-container + .accordion-container {
    margin-top: 1rem;
  }

  .accordion-trigger {
    white-space: normal;
    text-align: left;
    height: auto;

    .icon {
      transform: rotate(90deg);
    }

    &.is-active {
      &:not(:hover) {
        background: transparent;
      }

      .icon {
        transform: rotate(-90deg);
      }
    }
  }

  .accordion-body {
    overflow: hidden;
  }

  &.is-team {
    border-top: 1px solid $border;
    padding-top: $fixed-space-400;

    &:not(:last-child) {
      margin-bottom: $fluid-space-v5;
    }

    .accordion-body {
      padding: $fluid-space-v5 0;
    }
  }

  &.article-block {
    .accordion-trigger.is-active {
      border-bottom: none;
    }

    .accordion-body {
      padding: 0 $fluid-space-v3 $fluid-space-v3 $fluid-space-v3;
      background: $white;
      border: 1px solid $border;
      border-top: none;
    }
  }
}
