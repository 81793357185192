$icon-sizes: (
  'icon-v0': $fluid-icon-v0,
  'icon-v1': $fluid-icon-v1,
  'icon-v2': $fluid-icon-v2,
  'icon-1': $fixed-icon-1,
  'icon-2': $fixed-icon-2,
  'icon-3': $fixed-icon-3,
);

.icon {
  height: 1em;
  width: 1em;

  &.is-small {
    font-size: $fixed-icon-1;
  }

  &.is-normal {
    font-size: $fluid-icon-v1;
  }

  &.is-large {
    font-size: $fluid-icon-v0;
  }

  @each $name, $size in $icon-sizes {
    &.is-#{$name} {
      font-size: $size;
    }
  }
}

.icon-text {
  align-items: center;
  flex-wrap: nowrap;
  font-weight: $weight-semibold;

  .icon {
    &:not(:last-child) {
      margin-right: $fixed-space-150;
    }
  }
}
