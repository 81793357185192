nav {
  &.navbar {
    padding: $fluid-space-v3 $fluid-section-sides 0;

    @include mobile {
      padding-bottom: $fluid-space-v3;

      & + .section {
        padding-top: 0 !important;
      }
    }

    .navbar-brand {
      font-size: clamp(1rem, 0.9424rem + 0.2878vw, 1.25rem); // 16-20
      line-height: 1;
      min-height: auto;
      justify-content: space-between;
      align-items: flex-start;

      .container > & {
        margin-left: 0;
      }

      .logo {
        display: flex;

        svg,
        img {
          flex: none;
          height: $fluid-logo;
          width: auto;
          margin-right: $fixed-space-300;

          @include desktop {
            height: 5.625rem;
          }
        }
      }
    }

    .navbar-menu {
      .container > & {
        margin-right: 0;
      }

      .navbar-end {
        &.is-submenu {
          align-items: flex-start;
          gap: $fixed-space-200;
        }

        &.is-menu {
          align-items: flex-end;
          gap: $fluid-space-v3;
        }
      }

      .dropdown.is-hoverable {
        &:hover {
          .dropdown-trigger {
            background: $secondary;
          }
        }
      }
    }
  }

  &.is-header {
    overflow: auto;
    display: flex;
    gap: $fluid-space-v3;
  }
}

#navbar-menu {
  .accordion-trigger {
    .icon.is-toggled {
      display: none;
    }

    &.is-active {
      .icon {
        display: none;

        &.is-toggled {
          transform: rotate(0);
          display: block;
        }
      }
    }
  }

  .accordion-body {
    border-bottom: 4px solid $primary;
    padding: $fixed-space-200 0;
  }
}
