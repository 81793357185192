@mixin responsive-aspect-ratio {
  @include tablet {
    aspect-ratio: 4/3;
  }

  @include desktop {
    aspect-ratio: 16/9;
  }

  @include widescreen {
    aspect-ratio: 2/1;
  }
}

@mixin horizontal-box {
  display: flex;
  gap: $fluid-space-v6;

  header,
  .box-content {
    flex: 1 1 0;
  }
}
