.glightbox-container {
  .goverlay {
    background: $black;
  }

  .gslide-description {
    background: transparent;

    .gdesc-inner {
      max-width: 720px;
      color: $white-50;
      margin: 0 auto;
      background: transparent;
    }
  }

  .gprev,
  .gnext {
    font-size: $fluid-icon-v0;
  }

  .gclose {
    font-size: $fluid-icon-v1;
  }

  .gprev,
  .gnext,
  .gclose {
    color: $white-50;
  }
}
