.modal {
  // isolation: isolate;

  .modal-content {
    background: $white;
    display: flex;
    flex-direction: column;
    max-width: $modal-content-width;

    @include tablet {
      max-height: 90vh;
    }

    header {
      padding: $fluid-space-v3 $fluid-space-v3 0 $fluid-space-v3;
      display: flex;
      align-items: center;
      font-weight: $weight-bold;
    }

    .form-container {
      padding: $fixed-space-400 $fluid-space-v3 $fixed-space-600 $fluid-space-v3;

      & + main {
        padding-top: 0;
      }
    }

    main {
      flex: 1;
      overflow: auto;
      padding: $fluid-space-v3;

      .is-header-menu-item {
        border-bottom-color: $black;
        justify-content: space-between;
        padding: $fixed-space-300 $fixed-space-200;
        height: 65px;
      }

      > .is-header-menu-item:first-of-type {
        border-top: 4px solid $black;
      }
    }

    footer {
      padding: 0 $fluid-space-v3;
    }

    .form-container {
      max-width: none;
    }

    &.has-background-accent {
      .button {
        &.is-header-submenu-item,
        &.is-header-menu-item,
        &.is-accent {
          &:hover {
            background: $white;
          }
        }
      }
    }
  }

  &.is-right {
    .modal-content {
      max-height: none;
      max-width: 27.5rem;
      overflow: hidden;
      margin-right: 0;
      margin-left: auto;
      height: 100vh;

      @include tablet {
      }
    }
  }

  &.is-left {
    .modal-content {
      overflow: hidden;
      margin-left: 0;
      margin-right: auto;
      height: 100vh;

      @include tablet {
      }
    }
  }

  &.is-dialog {
    .modal-content {
      max-width: 480px;
    }
  }

  &[data-id='modal-search'] {
    @include tablet {
      justify-content: flex-start;

      .modal-content {
        max-height: 100vh;
        margin-top: 80px;
        margin-bottom: 80px;
      }
    }
  }
}
